@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  white-space: nowrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  .qrlabel {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 150px;
  }
  .qrprint {
    height: 600px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }
}

.toast-message {
  color: #fff;
  font-size: 16px;
  width: fit-content;
  padding: 10px;
  height: fit-content;
  overflow: hidden;
  word-wrap: break-word;
  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
}

.shadow-custom {
  --tw-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-button {
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25);
}
